<template>
  <div class="scl">
    <a
      class="scl__link"
      href="https://www.youtube.com/channel/UClHVCwhgXgpVXFqBKoXVR0Q"
      target="_blank"
    >
      <img
        alt="youtube"
        class="scl__img"
        src="@/assets/images/scl/bg-youtube.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_youtube"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">Youtube</div>
      </div>
    </a>
    <a
      class="scl__link"
      href="https://www.facebook.com/WoWBlessSociety"
      target="_blank"
    >
      <img
        alt="facebook"
        class="scl__img"
        src="@/assets/images/scl/bg-facebook.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_facebook"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">facebook</div>
      </div>
    </a>
    <a
      class="scl__link"
      href="https://twitter.com/WoWBless_com"
      target="_blank"
    >
      <img
        alt="twitter"
        class="scl__img"
        src="@/assets/images/scl/bg-twitter.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_twitter"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">twitter</div>
      </div>
    </a>
    <a class="scl__link" href="https://vk.com/wowbless" target="_blank">
      <img
        alt="vkontakte"
        class="scl__img"
        src="@/assets/images/scl/bg-vkontakte.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_vkontakte scl__vkontakte"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">vkontakte</div>
      </div>
    </a>
    <a
      class="scl__link"
      href="https://www.instagram.com/wowbless_com/"
      target="_blank"
    >
      <img
        alt="instagram"
        class="scl__img"
        src="@/assets/images/scl/bg-instagram.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_instagram"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">instagram</div>
      </div>
    </a>
    <a class="scl__link" href="https://discord.gg/S7As9s6AaM" target="_blank">
      <img
        alt="discord"
        class="scl__img"
        src="@/assets/images/scl/bg-discord.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_c-discord scl__discord"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">discord</div>
      </div>
    </a>
    <a class="scl__link" href="#" target="_blank">
      <img
        alt="skype"
        class="scl__img"
        src="@/assets/images/scl/bg-skype.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_skype-1"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">skype</div>
      </div>
    </a>
    <a class="scl__link" href="mailto:support@wowbless.com">
      <img alt="mail" class="scl__img" src="@/assets/images/scl/bg-mail.jpg" />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_email scl__email"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">mail</div>
      </div>
    </a>
    <a class="scl__link" href="https://t.me/wowbless" target="_blank">
      <img
        alt="telegram"
        class="scl__img"
        src="@/assets/images/scl/bg-telegram.jpg"
      />
      <div class="scl__content">
        <div class="scl__icon">
          <i class="scl__gwi gwi gwi_c-telegram scl__telegram"></i>
        </div>
        <div class="scl__line"></div>
        <div class="scl__name">telegram</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Social',
}
</script>

<style scoped></style>
